import { Action, createSlice } from "@reduxjs/toolkit";
import { HUBBLE_ENGINE_URL } from "../CONSTANTS";
import { ThunkAction } from 'redux-thunk';
import axiosService from "../../support/http/AxiosService";


export const customerQuestionnaireInitialState = () => ({
    questionnaireList : []
});

export const customerQuestionnaireSlice = createSlice({
    name: "customerQuestionnaire",
    initialState: customerQuestionnaireInitialState(),
    reducers: {
        setQuestionnaireList(state:any, action:any){
            state.questionnaireList = action.payload
        }
    }
});

export const {setQuestionnaireList} = customerQuestionnaireSlice.actions;


export const getQuestionnaireList = (id: any): ThunkAction<void, any, unknown, Action> => 
async (dispatch: any, getState: any) => {
  axiosService('GET',
    HUBBLE_ENGINE_URL + `projectQuestionAnswerList/${id}`,true
  ).then((response) => {
    if (response.data.success) {
      dispatch(setQuestionnaireList(response.data.data.QuestionAnswerList));
    }
  });
};

//updateSiteVisitDate

export const setSiteVisitDate = (data: any): ThunkAction<void, any, unknown, Action> => 
async (dispatch: any, getState: any) => {
  axiosService('POST',
    HUBBLE_ENGINE_URL + `updateSiteVisitDate`,true, null, false,
    data
  ).then((response) => {
    if (response.data.success) {
      // dispatch(setQuestionnaireList(response.data.data.QuestionAnswerList));
    }
  });
};


export default customerQuestionnaireSlice.reducer;