import React from 'react';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import {CDN_URL} from '../../config';
import { GOOGLE_ID } from '../../store/CONSTANTS';
import './Login.style.scss';
import {establishCommunicationToSSO} from '../../utils/helper'


const Login = () =>{
const onSuccess = (res: any) => {
  establishCommunicationToSSO({
    "event" : "login",
    "token" : res?.credential, 
    "avatar" : res?.profileObj?.imageUrl
})
} 
  const onFailure = (res: any) => {
    // LAYOUT.showToast('ERROR' ,`Failed to login.`)
  };

    return(
        <>
         <div className="outer-cont">
        <div className = "d-flex justify-content-center login-container">
        <img src={`${CDN_URL}partner_assets/electrum/electrum-logo.svg`} alt="logo" />
          <div className="cont-log">
          <h2>Welcome to Hubble!</h2>
          <GoogleOAuthProvider clientId= {GOOGLE_ID}>
         <GoogleLogin
          clientId= {GOOGLE_ID}
          buttonText="Login With Google"
          onSuccess={onSuccess}
          onFailure={onFailure}
          cookiePolicy={'single_host_origin'}
          style={{ marginTop: '100px', width: '200px'}}
          isSignedIn={false}
          {...({} as any)}
         />
         </GoogleOAuthProvider>
         <p className="copy">Copyright © {new Date().getFullYear()}. All rights reserved.</p>
         </div>
         </div>
         </div>
        </>
    )
}

export default Login;

