import {toast} from 'react-toastify';

export const showToast = (type:any,msg: any) => {
    switch(type){
        case 'SUCCESS':
            toast.success(msg,{
                position: 'top-right'
            })
        break;
        case 'ERROR':
            toast.error(msg,{
                position: 'top-right'
            })
        break;
        case 'INFO':
            toast.info(msg,{
                position: 'top-right'
            })
        break;
        default:
            return false;
    }
}