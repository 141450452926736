import React from "react";
import { useDropzone } from "react-dropzone";
import ButtonCustom from "../Button";
import './dropZone.scss';

const Dropzone = ({ onDrop, accept }) => {
  const fileTypes = accept || {
    "image/*": [],
    "application/pdf": [],
    "text/csv": []
  };

  const { getRootProps, getInputProps, isDragActive, acceptedFiles, open } =
    useDropzone({
      accept: fileTypes,
      onDrop
    });

  const files = acceptedFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  const handleSelectClick = (e) => {
    e.stopPropagation(); // Prevents file picker "double open" bug
    open();
  };

  return (
    <>
      <div {...getRootProps({ className: "dropzone" })}>
        <input className="input-zone" {...getInputProps()} multiple type="file" />
        <div className="text-center">
          {isDragActive ? (
            <p className="dropzone-content">Release to drop the files here</p>
          ) : (
            <p className="dropzone-content text-center">Drag and drop files here</p>
          )}
          <p style={{ margin: "10px 0" }} className="text-center">or</p>
          <ButtonCustom
            label="Select"
            variant="contained"
            buttonColor="error"
            styleClass="btn button-view"
            handleClick={handleSelectClick}
            className="btn"
          />
          {files.length > 0 && (
            <p style={{ marginTop: "5px" }} className="text-center">
              {files.length} {files.length > 1 ? "files" : "file"} selected
            </p>
          )}
        </div>
      </div>
    </>
  );
};

export default Dropzone;
